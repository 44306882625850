$(document).ready(function (){
    $("#table-press").DataTable({
        proccesing: true,
        serverSide: true,
        language: {
            "url": "//cdn.datatables.net/plug-ins/1.10.16/i18n/Spanish.json"
        },
        responsive: true,
        ajax: 'get-press',
        columns: [
            {data: 'id', name: 'id'},
            {data: 'name', name: 'name'},
        ],
        "columnDefs": [{
            "targets": 2,
            "data": "download_link",
            "render": function (data, type, row, meta) {

                let status = get_global_status(row.status);
                return '<a href="#" data-toggle="tooltip" data-placement="top" title="'+ status.title +'" ' +
                    'class="btn btn-' + status.color_clas + ' change-press-status btn-sm"  ' +
                    'data-id ="' + row.id + '"><span class="' + status.icon + '"></span></a> '+

                    '<button data-toggle="tooltip" data-placement="top" title="Editar" class="btn btn-primary ' +
                    'edit-press btn-sm"' +
                    'data-data ="' + row + '"><i class="far fa-edit"></i></i></button> '
            }
        }]
    });
    const list_press = $("#table-press").DataTable();

    $("#add-press").on('click',function (e){
        e.preventDefault()
        $('#form-press')[0].reset();
        $(".item-press-id").val('');
        $(".title-press-text").text("Agregar medio")
        const modalPress = new bootstrap.Modal('#modal-press')
        modalPress.show()
    })

    $("#table-press tbody").on('click','.edit-press',function (e){
        e.preventDefault()
        $(".title-type-text").text("Editar tipo")
        rowData = list_press.row( $(this).parents('tr') ).data();
        $.each(rowData,function (index,value){
            $(".item-press-"+index).val(value);
        });
        const modalTypePress = new bootstrap.Modal('#modal-press')
        modalTypePress.show()

    })

    $("#table-press tbody").on('click','.change-press-status',function (e){
        e.preventDefault()
        let data = new FormData()
        data.append('id',$(this).data('id'))
        sendData(data,'change-press-status','')
    })

    $("#form-press").on('submit',function (e){
        e.preventDefault()
        let data = new FormData(this)
        $(".loading").addClass("loading_");
        sendData(data,'form-press','modal-press');
    })

    function sendData(data,route,modal)
    {
        $.ajax({
            url: route,
            type: "post",
            data: data,
            processData: false,
            contentType: false,
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            success: function( data ) {
                $(".loading").removeClass("loading_");
                if (data.success == "success")
                {
                    alertSuccess(data.msg)
                    if (modal !== "") {
                        bootstrap.Modal.getOrCreateInstance(document.getElementById(modal)).hide()
                    }
                    $("#table-press").DataTable().ajax.reload();
                }else{
                    alertError(data.msg)
                }
            },
            error: function (errors, xhr, status,) {
                $(".loading").removeClass("loading_");
                var indices = errors.responseJSON.errors;
                $.each(indices, function (index, value) {
                    $(".item-type"+index).addClass('is-invalid')
                    $(".item-type-error-"+index).text(value);
                });
            }
        })
    }
})
