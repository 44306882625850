$(document).ready(function (){
    $("#table-guest").DataTable({
        proccesing: true,
        serverSide: true,
        language: {
            "url": "//cdn.datatables.net/plug-ins/1.10.16/i18n/Spanish.json"
        },
        responsive: true,
        ajax: 'get-guest',
        columns: [
            {data: 'name', name: 'name'},
            {data: 'stage_name', name: 'stage_name'},
            {data: 'email', name: 'email'},
            {data: 'phone', name: 'phone'},
            {data: 'residencia', name: 'residencia'}
        ],
        "columnDefs": [{
            "targets": 5,
            "data": "download_link",
            "render": function (data, type, row, meta) {

                let status = get_global_status(row.status);
                return '<button data-toggle="tooltip" data-placement="top" title="Editar" class="btn btn-primary ' +
                            'edit-guest btn-sm"' +
                            'data-data ="' + row + '"><i class="far fa-edit"></i></i></button> ' +

                        '<a href="#" class="btn btn-success btn-sm download-itineario" data-val="'+ row.slug +'" ' +
                    '        data-toggle="tooltip" title="Descargar Itinerario"><i class="fa-solid fa-download"></i></a> ' +

                    '<a href="#" class="btn btn-info btn-sm download-semblance" data-val="'+ row.slug +'" ' +
                    '        data-toggle="tooltip" title="Descargar semblanza"><i class="fa-regular fa-circle-down"></i></a> ' +

                        '<a href="#" data-toggle="tooltip" data-placement="top" title="'+ status.title +'" ' +
                            'class="btn btn-' + status.color_clas + ' change-guest-status btn-sm"  ' +
                            'data-slug ="' + row.slug + '"><span class="' + status.icon + '"></span></a> '
            }
        }]
    });

    const list_guest = $("#table-guest").DataTable();
    let avatar_img = "images/avatar/guest.png";

    $("#add-guest").on('click',function (e){
        e.preventDefault()
        clearSpan()
        $(".item-id").val();
        $('#form-add-guest')[0].reset()
        $(".title-guest-text").text("Agregar invitado.")
        $(".avatarImg").attr("src",avatar_img);
        const modalGuest = new bootstrap.Modal('#modal-guest')
        modalGuest.show()
    })

    $("#table-guest tbody").on('click','.download-itineario',function () {
        let slug = $(this).data('val');
        window.location.href='/download-itinerario/'+slug+'';
    });

    $("#table-guest tbody").on('click','.change-guest-status',function (e){
        e.preventDefault()
        let data = new FormData()
        data.append('slug',$(this).data('slug'))
        $(".loading").addClass("loading_")
        sendData(data,'change-guest-status', '1')
    })

    $("#table-guest tbody").on('click','.edit-guest',function (e){
        e.preventDefault()
        const modalGuest = new bootstrap.Modal('#modal-guest')
        $(".title-user-text").text("Editar invitado")
        rowData = list_guest.row( $(this).parents('tr') ).data();
        $.each(rowData,function (index,value){
            $(".item-"+index).val(value)
            if (index === "avatar") {
                validaAvatar(value)
            }
        });
        modalGuest.show();
    })

    $("#table-guest tbody").on('click','.download-semblance',function (e){
        let slug = $(this).data('val');
        window.location.href='/download-semblance/'+slug;
    });

    $("#table-guest tbody").on('click','.download-itinerario',function (e){
        let slug = $(this).data('val');
        window.location.href='/download-itinerario/'+slug;
    });

    $("#form-add-guest").on('submit',function (e){
        e.preventDefault();
        $(".loading").addClass("loading_")
        let data = new FormData(this)
        $(".loading").addClass("loading_")
        sendData(data,'send-guest','0')
    })

    function sendData(data,route,closeModal)
    {
        $.ajax({
            url: route,
            type: "post",
            data: data,
            processData: false,
            contentType: false,
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            success: function( data ) {
                $(".loading").removeClass("loading_")
                if (data.success === "success") {
                    alertSuccess(data.msg)
                    if (closeModal === "0") {
                        bootstrap.Modal.getOrCreateInstance(document.getElementById('modal-guest')).hide()
                    }

                    $("#table-guest").DataTable().ajax.reload();
                } else {
                    alertError(data.msg)
                }
            },
            error: function (errors, xhr, status,) {
                $(".loading").removeClass("loading_");
                var indices = errors.responseJSON.errors;
                $.each(indices, function (index, value) {
                    $(".item-"+index).addClass('is-invalid')
                    $(".item-error-"+index).text(value)
                });
            }
        })
    }

    function validaAvatar(img)
    {
        if (img === " " || img === null) {
            $(".avatarImg").attr("src", avatar_img);
        } else {
            $(".avatarImg").attr("src", img);
        }
    }

    function clearSpan() {
        $(".item-error-name").text('');
        $(".item-error-stage_name").text('');
        $(".item-error-email").text('');
        $(".item-error-phone").text('');

        $(".item-name").removeClass('is-invalid')
        $(".item-stage_name").removeClass('is-invalid')
        $(".item-email").removeClass('is-invalid')
        $(".item-phone").removeClass('is-invalid')
    }
});
