$(document).ready(function (){
    $("#table-sedes").DataTable({
        proccesing: true,
        serverSide: true,
        language: {
            "url": "//cdn.datatables.net/plug-ins/1.10.16/i18n/Spanish.json"
        },
        responsive: true,
        ajax: 'get-sedes',
        columns: [
            {data: 'id', name: 'id'},
            {data: 'name', name: 'name'},
            {data: 'direction', name: 'direction'},
        ],
        "columnDefs": [{
            "targets": 3,
            "data": "download_link",
            "render": function (data, type, row, meta) {
                console.log(row);
                let status = get_global_status(row.status);
                return '<button data-toggle="tooltip" data-placement="top" title="Editar" class="btn btn-primary ' +
                    'edit-sede btn-sm"' +
                    'data-data ="' + row + '"><i class="far fa-edit"></i></i></button> ' +

                    '<a href="#" data-toggle="tooltip" data-placement="top" title="'+ status.title +'" ' +
                    'class="btn btn-' + status.color_clas + ' change-sede-status btn-sm"  ' +
                    'data-id ="' + row.id + '"><span class="' + status.icon + '"></span></a> '
            }
        }]
    })

    const list_sedes = $("#table-sedes").DataTable()

    $("#add-sede").on('click',function (e){
        e.preventDefault()
        $('#form-add-sede')[0].reset()
        $(".title-sede-text").text("Agregar sede.")
        const modalSedes = new bootstrap.Modal('#modal-sedes')
        modalSedes.show()
    })

    $("#table-sedes tbody").on('click','.edit-sede',function (e){
        e.preventDefault()
        $(".title-sede-text").text("Editar sede.")
        const modalSede = new bootstrap.Modal('#modal-sedes')
        rowData = list_sedes.row( $(this).parents('tr') ).data();
        $.each(rowData,function (index,value){
            $(".item-sede-"+index).val(value)
        });
        modalSede.show();
    })

    $("#table-sedes tbody").on('click','.change-sede-status',function (e){
        e.preventDefault()
        let data = new FormData()
        data.append('id',$(this).data('id'))
        sendData(data,'change-sede-status')
    })

    $("#form-add-sede").on('submit',function (e){
        e.preventDefault()
        let data = new FormData(this)
        $(".loading").addClass("loading_")
        sendData(data,'send-sede')
    })

    function sendData(data,route)
    {
        $.ajax({
            url: route,
            type: "post",
            data: data,
            processData: false,
            contentType: false,
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            success: function( data ) {
                $(".loading").removeClass("loading_")

                if (data.success == "success") {
                    alertSuccess(data.msg)
                    bootstrap.Modal.getOrCreateInstance(document.getElementById('modal-sedes')).hide()
                    $("#table-sedes").DataTable().ajax.reload();
                } else {
                    alertError(data.msg)
                }
            },
            error: function (errors, xhr, status,) {
                $(".loading").removeClass("loading_");
                var indices = errors.responseJSON.errors;
                $.each(indices, function (index, value) {
                    $(".item-"+index).addClass('is-invalid')
                    $(".item-error-"+index).text(value)
                });
            }
        })
    }




})
