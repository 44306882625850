require('./bootstrap');
require("bootstrap-datepicker");
require('./globlal/get_status')
require('./globlal/get_alerts')
require('./permisos/roles/roles')
require('./users/users')
/*require('./invitados_')*/
require('./invitados/invitados')
require('./invitados/find_email_or_phone')
require('./catalogo/transport')
require('./catalogo/services')
require('./catalogo/type_events')
require('./catalogo/sedes')
require('./catalogo/forums')
require('./logistica/logistica')
require('./logistica/logistica-fn')
require('./eventos/selects')
require('./eventos/auxiliar')
require('./eventos/eventos')
require('./press/types')
require('./press/press')
require('./press/eventos-prensa')
require('./socials/socials')

$('.datepicker').datepicker({
    format: 'yyyy-mm-dd',
    startDate: '-3d'
});

$('.select2').select2();
