$(document).ready(function (){
    window.alertSuccess = function(msg)
    {
        swal.fire({
            position: 'center',
            icon: "success",
            title: msg,
            showConfirmButton: false,
            timer: 1500,
            iconColor: '#6CEE00FF',
        })
    }

    window.alertError = function(msg)
    {
        swal.fire({
            confirmButtonColor: "#eb0c0c",
            position: 'center',
            icon: "error",
            title: msg,
            showConfirmButton: true,
        })
    }


});
