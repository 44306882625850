$(document).ready(function (){
    window.get_global_status = function(status)
    {
        if (status == "A") {
            icon = "far fa-thumbs-up";
            title = "Desactivar";
            colorClas = "success";
        } else {
            icon = "far fa-thumbs-down";
            title = "Activar";
            colorClas = "danger";
        }
        return {'icon' : icon,'title':title,'color_clas':colorClas};
    }

    window.get_global_visible = function(status)
    {
        if (status == "Y") {
            icon = "far fa-thumbs-up";
            title = "Desactivar";
            colorClas = "success";
        } else {
            icon = "far fa-thumbs-down";
            title = "Activar";
            colorClas = "danger";
        }
        return {'icon' : icon,'title':title,'color_clas':colorClas};
    }

    window.get_global_visible_event = function(status)
    {
        if (status == "Y") {
            icon = "fas fa-eye";
            title = "Desactivar";
            colorClas = "success";
        } else {
            icon = "far fa-eye-slash";
            title = "Activar";
            colorClas = "danger";
        }
        return {'icon' : icon,'title':title,'color_clas':colorClas};
    }

});
