$(document).ready(function (){
    $('.select2-sede-provesco').select2({
        dropdownParent: $('#modal-forums')
    });

    $("#table-forums").DataTable({
        proccesing: true,
        serverSide: true,
        language: {
            "url": "//cdn.datatables.net/plug-ins/1.10.16/i18n/Spanish.json"
        },
        responsive: true,
        ajax: 'get-forums',
        columns: [
            {data: 'id', name: 'id'},
            {data: 'name', name: 'name'},
            {data: 'type', name: 'type'},
            {data: 'forum_sedes.name', name: 'forum_sedes.name'},
        ],
        "columnDefs": [{
            "targets": 4,
            "data": "download_link",
            "render": function (data, type, row, meta) {
                console.log(row);
                let status = get_global_status(row.status);
                return '<button data-toggle="tooltip" data-placement="top" title="Editar" class="btn btn-primary ' +
                    'edit-forum btn-sm"' +
                    'data-data ="' + row + '"><i class="far fa-edit"></i></i></button> ' +

                    '<a href="#" data-toggle="tooltip" data-placement="top" title="'+ status.title +'" ' +
                    'class="btn btn-' + status.color_clas + ' change-forum-status btn-sm"  ' +
                    'data-id ="' + row.id + '"><span class="' + status.icon + '"></span></a> '
            }
        }]
    })

    const list_forums = $("#table-forums").DataTable()

    $("#add-forum").on('click',function (e){
        e.preventDefault()
        $('#form-add-forum')[0].reset()
        $(".title-forum-text").text("Agregar foro.")
        const modalForum = new bootstrap.Modal('#modal-forums')
        modalForum.show()
    })

    $("#table-forums tbody").on('click','.edit-forum',function (e){
        e.preventDefault()
        $(".title-forum-text").text("Editar foro.")
        const modalForum = new bootstrap.Modal('#modal-forums')
        rowData = list_forums.row( $(this).parents('tr') ).data();
        $.each(rowData,function (index,value){
            $(".item-forum-"+index).val(value)
            if (index == "sede_id") {
                $('.select2-sede-provesco').append(value).trigger('change');
            }
        });
        modalForum.show();
    })

    $("#table-forums tbody").on('click','.change-forum-status',function (e){
        e.preventDefault()
        let data = new FormData()
        data.append('id',$(this).data('id'))
        sendData(data,'change-forum-status')
    })

    $("#form-add-forum").on('submit',function (e){
        e.preventDefault()
        let data = new FormData(this)
        $(".loading").addClass("loading_")
        sendData(data,'send-forum')
    })

    function sendData(data,route)
    {
        $.ajax({
            url: route,
            type: "post",
            data: data,
            processData: false,
            contentType: false,
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            success: function( data ) {
                $(".loading").removeClass("loading_")

                if (data.success == "success") {
                    alertSuccess(data.msg)
                    bootstrap.Modal.getOrCreateInstance(document.getElementById('modal-forums')).hide()
                    $("#table-forums").DataTable().ajax.reload();
                } else {
                    alertError(data.msg)
                }
            },
            error: function (errors, xhr, status,) {
                $(".loading").removeClass("loading_");
                var indices = errors.responseJSON.errors;
                $.each(indices, function (index, value) {
                    $(".item-"+index).addClass('is-invalid')
                    $(".item-error-"+index).text(value)
                });
            }
        })
    }


})
