$(document).ready(function () {
    $('.select2-provesco').select2({
        dropdownParent: $('#modal-users')
    });


    $("#table-users").DataTable({
        proccesing: true,
        serverSide: true,
        language: {
            "url": "//cdn.datatables.net/plug-ins/1.10.16/i18n/Spanish.json"
        },
        responsive: true,
        ajax: 'get-users',
        columns: [
            {data: 'id', name: 'id'},
            {data: 'name', name: 'name'},
            {data: 'email', name: 'email'},
            {data: 'profile', name: 'profile'}
        ],
        "columnDefs": [{
            "targets": 4,
            "data": "download_link",
            "render": function (data, type, row, meta) {

                let status = get_global_status(row.status);
                return '<a href="#" data-toggle="tooltip" data-placement="top" title="' + status.title + '" ' +
                    'class="btn btn-' + status.color_clas + ' change-user-status btn-sm"  ' +
                    'data-id ="' + row.id + '"><span class="' + status.icon + '"></span></a> ' +

                    '<button data-toggle="tooltip" data-placement="top" title="Editar" class="btn btn-primary ' +
                    'edit-user btn-sm"' +
                    'data-data ="' + row + '"><i class="far fa-edit"></i></i></button> ' +

                    '<button data-toggle="tooltip" data-placement="top" title="Generar nueva contraseñá" class="btn btn-primary ' +
                    'update-password-user btn-sm"' +
                    'data-data ="' + row.id + '"><i class="fa-solid fa-unlock-keyhole"></i></button>'
            }
        }]
    });

    const list_users = $("#table-users").DataTable();

    $("#add-user").on('click', function (e) {
        e.preventDefault()
        $('#form-add-user')[0].reset();
        $(".title-user-text").text("Agregar usuario")
        const modalUser = new bootstrap.Modal('#modal-users')
        modalUser.show()
    })

    $("#form-add-user").on('submit', function (e) {
        e.preventDefault()
        $(".loading").addClass("loading_");
        let data = new FormData(this)
        sendData(data, 'add-user', 'modal-users')
    })

    $("#table-users tbody").on('click', '.change-user-status', function (e) {
        e.preventDefault()
        let data = new FormData();
        data.append('id', $(this).data('id'))
        sendData(data, 'change-status-user', '1')
    })

    $("#table-users tbody").on('click', '.update-password-user', function (e) {
        e.preventDefault()
        $('#form-change-password')[0].reset();
        let id = $(this).data('data')
        $(".item-user-id").val(id)
        const modalCambioPassword = new bootstrap.Modal('#modal-cambio-password')
        modalCambioPassword.show()
    })

    $("#form-change-password").on('submit', function (e) {
        e.preventDefault()
        $(".loading").addClass("loading_");
        let data = new FormData(this)
        sendData(data, 'update-password', 'modal-cambio-password')
    })

    $("#table-users tbody").on('click', '.edit-user', function (e) {
        e.preventDefault()
        $(".title-user-text").text("Editar usuario")

        rowData = list_users.row($(this).parents('tr')).data();
        $.each(rowData, function (index, value) {
            $(".item-user-" + index).val(value);
            if (index == "profile") {
                $('.select2-provesco').append(value).trigger('change');
            }
        });
        const modalUser = new bootstrap.Modal('#modal-users')
        modalUser.show();
        $(".modal-title").html('Editar usuario');
    })


    function sendData(data, route, modal) {
        $.ajax({
            url: route,
            type: "post",
            data: data,
            processData: false,
            contentType: false,
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            success: function (data) {
                $(".loading").removeClass("loading_");
                if (data.success == "success") {
                    alertSuccess(data.msg)
                    if (modal != "1") {
                        bootstrap.Modal.getOrCreateInstance(document.getElementById(modal)).hide()
                    }

                    $("#table-users").DataTable().ajax.reload();
                } else {
                    alertError(data.msg)
                }
            },
            error: function (errors, xhr, status,) {
                $(".loading").removeClass("loading_");
                var indices = errors.responseJSON.errors;
                $.each(indices, function (index, value) {
                    $(".item-" + index).addClass('is-invalid')
                    $(".item-error-" + index).text(value);
                });
            }
        })
    }
})
