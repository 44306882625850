$(document).ready(function (){
    $('.js-example-basic-multiple').select2({width: '100%', height:'450px',
        dropdownParent: $('#modal-eventos')
    });

    $('.select-evento-tipo').select2({width: '100%', dropdownParent: $('#modal-eventos')});
    $('.select-evento-programacion').select2({width: '100%', dropdownParent: $('#modal-eventos')});
    $('.select-evento-formato').select2({width: '100%', dropdownParent: $('#modal-eventos')});
    $('.select-evento-presentador').select2({width: '100%', dropdownParent: $('#modal-eventos')});
    $('.select-evento-moderador').select2({width: '100%', dropdownParent: $('#modal-eventos')});
    $('.select-evento-sede').select2({width: '100%', dropdownParent: $('#modal-eventos')});

    $('.js-social-basic-multiple').select2({width: '100%', height:'450px',
        dropdownParent: $('#modal-sociales')
    });
    $('.select-social-lugar_id').select2({width: '100%', dropdownParent: $('#modal-sociales')});
})
