$(document).ready(function () {
    $("#table-socials").DataTable({
        proccesing: true,
        serverSide: true,
        language: {
            "url": "//cdn.datatables.net/plug-ins/1.10.16/i18n/Spanish.json"
        },
        responsive: true,
        ajax: 'get-socials-events',
        columns: [
            {data: 'id', name: 'id'},
            {data: 'invitados', name: 'invitados'},
            {data: 'servicio_sociales.name', name: 'servicio_sociales.name'},
            {data: 'actividad', name: 'actividad'},
            {data: 'fecha', name: 'fecha'},
            {data: 'hora_inicio_traslado', name: 'hora_inicio_traslado'},
            {data: 'hora_fin_traslado', name: 'hora_fin_traslado'},
        ],
        "columnDefs": [{
            "targets": 7,
            "data": "download_link",
            "render": function (data, type, row, meta) {
                console.log(row)
                return '<button data-toggle="tooltip" data-placement="top" title="Editar" class="btn btn-primary ' +
                    'edit-social btn-sm"' +
                    'data-data ="' + row + '"><i class="far fa-edit"></i></button>' +
                    '<button data-toggle="tooltip" data-placement="top" title="Eliminar" class="btn btn-danger m-1 ' +
                    'delete-social-event btn-sm"' +
                    'data-id ="' + row.id + '"><i class="fa-solid fa-trash-can"></i></button>'

            }
        }]
    });
    const list_socials = $("#table-socials").DataTable();

    $("#new-socials").on('click', function (e) {
        $('#form-socials')[0].reset()
        $(".item-social-id").val('')
        $(".title-social-text").text("Agregar")
        $(".item-social-invitado_id").val("").trigger('change')
        $(".item-social-lugar_id").val("").trigger('change')
        const modalSocial = new bootstrap.Modal('#modal-sociales')
        modalSocial.show()
    })


    $("#table-socials tbody").on('click', '.edit-social', function (e) {
        e.preventDefault()
        rowData = list_socials.row($(this).parents('tr')).data();
        $.each(rowData, function (index, value) {
            $(".item-social-" + index).val(value)
            asignarSelects(index,value)
        })
        const modalSocial = new bootstrap.Modal('#modal-sociales')
        modalSocial.show()
    })

    $("#table-socials tbody").on('click','.delete-social-event',function (e){
        e.preventDefault()
        let id = $(this).data('id')
        let data = new FormData()
        data.append('id',id)

        swal.fire({
            title: '¿Estas seguro?',
            text: "El registro sera eliminado",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, eliminar !'
        }).then((result) => {
            if (result.isConfirmed) {
                sendData(data,'delete-social-event','0')
            }
        })
    })


    $("#form-socials").on('submit', function (e) {
        e.preventDefault()
        let data = new FormData(this)
        $(".loading").addClass("loading_");
        sendData(data, 'send-data-social', 'modal-sociales');
    })

    function asignarSelects(index,value)
    {
        let invitados = new Array()
        if (index === "lugar_id") {
            $(".item-social-lugar_id").append(value).trigger('change');
        }

        if (index === "invitados_sociales") {
            $.each(value, function (key, element) {
                invitados.push('id', element.invitado_id);
            })
            $(".item-social-invitado_id").val(invitados).trigger('change')
        }
    }


    function sendData(data, route, closeModal) {
        $.ajax({
            url: route,
            type: "post",
            data: data,
            processData: false,
            contentType: false,
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            success: function (data) {
                $(".loading").removeClass("loading_")
                if (data.success === "success") {
                    alertSuccess(data.msg)
                    if (closeModal != "0") {
                        bootstrap.Modal.getOrCreateInstance(document.getElementById(closeModal)).hide()
                    }
                    $("#table-socials").DataTable().ajax.reload()
                } else {
                    alertError(data.msg)
                }
            },
            error: function (errors, xhr, status,) {
                $(".loading").removeClass("loading_");
                var indices = errors.responseJSON.errors;
                $.each(indices, function (index, value) {
                    $(".item-" + index).addClass('is-invalid')
                    $(".item-error-" + index).text(value)
                });
            }
        })
    }
})
