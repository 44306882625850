$(document).ready(function (){

    $("#table-types-events").DataTable({
        proccesing: true,
        serverSide: true,
        language: {
            "url": "//cdn.datatables.net/plug-ins/1.10.16/i18n/Spanish.json"
        },
        responsive: true,
        ajax: 'get-types',
        columns: [
            {data: 'id', name: 'id'},
            {data: 'name', name: 'name'},
            {data: 'type', name: 'type'},
        ],
        "columnDefs": [{
            "targets": 3,
            "data": "download_link",
            "render": function (data, type, row, meta) {
                console.log(row);
                let status = get_global_status(row.status);
                return '<button data-toggle="tooltip" data-placement="top" title="Editar" class="btn btn-primary ' +
                    'edit-type_event btn-sm"' +
                    'data-data ="' + row + '"><i class="far fa-edit"></i></i></button> ' +

                    '<a href="#" data-toggle="tooltip" data-placement="top" title="'+ status.title +'" ' +
                    'class="btn btn-' + status.color_clas + ' change-type_event-status btn-sm"  ' +
                    'data-id ="' + row.id + '"><span class="' + status.icon + '"></span></a> '
            }
        }]
    });

    const list_types_events = $("#table-types-events").DataTable()

    $("#add-type-events").on('click',function (e){
        e.preventDefault()
        $('#form-add-type_event')[0].reset()
        $(".item-type_event-id").val("");
        $(".title-type_event-text").text("Agregar categoria.")
        const modalTypeEvents = new bootstrap.Modal('#modal-type_events')
        modalTypeEvents.show()
    })

    $("#table-types-events tbody").on('click','.edit-type_event',function (e){
        e.preventDefault()
        $(".title-type_events-text").text("Editar categoria.")
        const modalTypeEvent = new bootstrap.Modal('#modal-type_events')
        rowData = list_types_events.row( $(this).parents('tr') ).data();
        $.each(rowData,function (index,value){
            $(".item-type_event-"+index).val(value)
        });
        modalTypeEvent.show();
    })

    $("#table-types-events tbody").on('click','.change-type_event-status',function (e){
        e.preventDefault()
        let data = new FormData()
        data.append('id',$(this).data('id'))
        sendData(data,'change-type_event-status')
    })

    $("#form-add-type_event").on('submit',function (e){
        e.preventDefault()
        let data = new FormData(this)
        $(".loading").addClass("loading_")
        sendData(data,'send-type_event')
    })

    function sendData(data,route)
    {
        $.ajax({
            url: route,
            type: "post",
            data: data,
            processData: false,
            contentType: false,
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            success: function( data ) {
                $(".loading").removeClass("loading_")

                if (data.success == "success") {
                    alertSuccess(data.msg)
                    bootstrap.Modal.getOrCreateInstance(document.getElementById('modal-type_events')).hide()
                    $("#table-types-events").DataTable().ajax.reload();
                } else {
                    alertError(data.msg)
                }
            },
            error: function (errors, xhr, status,) {
                $(".loading").removeClass("loading_");
                var indices = errors.responseJSON.errors;
                $.each(indices, function (index, value) {
                    $(".item-type_event"+index).addClass('is-invalid')
                    $(".item-type_event-error-"+index).text(value)
                });
            }
        })
    }


})
