$(document).ready(function () {
    /**
     * Al seleccinar el tipo de evento mesa, se muestra
     * el div de moderadores
     */

    $(".item-evento-tipo_id").on('change', function () {

        let option_select = $(this).find(":selected").val();
        //10 : Presentacion editorial
        //11 : Mesa
        if (option_select === "10" || option_select == "16") {
            $('.item-moderador_id').val(null).trigger('change');
            $(".box-presentador").show();
            $(".box-moderador").hide();
        } else if (option_select === "11") {
            $('.item-presentador_id').val(null).trigger('change');
            $(".box-moderador").show();
            $(".box-presentador").hide();
        } else {
            $('.item-presentador_id').val(null).trigger('change');
            $('.item-moderador_id').val(null).trigger('change');
            $(".box-presentador").hide();
            $(".box-moderador").hide();
        }
    })


    $(".item-evento-formato").on('change', function () {
        $(".loading").addClass("loading_");
        let option_select = $(this).find(":selected").text();
        let data = new FormData()
        $('.item-evento-sede_id').val(null).trigger('change')
        $('.item-evento-sede_id').empty()

        data.append('type', option_select);
        $.ajax({
            url: "get-sede-by-type",
            type: "POST",
            data: data,
            processData: false,
            contentType: false,
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            success: function (data) {
                $(".loading").removeClass("loading_");
                $.each(data, function (idx, obj) {
                    $('.item-evento-sede_id').append($('<option>', {
                        value: obj.id,
                        text: obj.name
                    }));
                });
            },
            error: function (errors) {
                var indices = errors.responseJSON.errors;
                $.each(indices, function (index, value) {
                    console.log(index, value);
                    $(".error-" + index).append(value);
                    $(".error-" + index).show();
                });
            }
        })
    });

    window.updatePromotor = function (param, element) {
        if (element.checked) {
            $(".quantity" + param).prop('readonly', false);
        } else {
            $(".quantity" + param).prop('readonly', true);
            swal.fire({
                icon: 'info',
                title: '¡ Atencion !',
                text: 'Si desea activar nuevamente este libro para el presentador debera ingresar nuevamente una cantidad',
            })
            $(".quantity" + param).val(0);
        }
    }

    $('input[type="checkbox"]').click(function () {
        if ($(this).prop("checked") == true) {
            $(".box-colaborador").show();
        }
        if ($(this).prop("checked") == false) {
            $('.item-moderador_id').val(null).trigger('change');
            $(".box-colaborador").hide();
        }
    });

})
