$(document).ready(function (){
    $("#table-roles").DataTable({
        proccesing: true,
        serverSide: true,
        language: {
            "url": "//cdn.datatables.net/plug-ins/1.10.16/i18n/Spanish.json"
        },
        responsive: true,
        ajax: 'get-users',
        columns: [
            {data: 'id', name: 'id'},
            {data: 'name', name: 'name'},
        ],
    });

    window.new_role = function ()
    {
        $('#form-add-rol')[0].reset();
        const modalRole = new bootstrap.Modal('#modal-roles')
        modalRole.show()
    }

    $("#form-add-rol").on('submit',function (e){
        e.preventDefault()
        let data = new FormData(this)
        $(".loading").addClass("loading_")
        sendData(data,'add-rol')
    })

    function sendData(data,route)
    {
        $.ajax({
            url: route,
            type: "post",
            data: data,
            processData: false,
            contentType: false,
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            success: function( data ) {
                $(".loading").removeClass("loading_");
                if (data.success == "success")
                {
                    alertSuccess(data.msg)
                    bootstrap.Modal.getOrCreateInstance(document.getElementById('modal-roles')).hide()
                    $("#table-roles").DataTable().ajax.reload();
                }else{
                    alertError(data.msg)
                }
            },
            error: function (errors, xhr, status,) {
                $(".loading").removeClass("loading_");
                var indices = errors.responseJSON.errors;
                $.each(indices, function (index, value) {
                    $(".item-"+index).addClass('is-invalid')
                    $(".item-error-"+index).text(value);
                });
            }
        })
    }

});
