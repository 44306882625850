$(".item-email").on('keyup',function (e){
    e.preventDefault();
    let data;
    let str = $(".item-email").val();
    $at = str.indexOf('@')
    if ($at > 0)
    {
        data = new FormData;
        data.append('field',"email")
        data.append('value',str)
        findEmailOrPhone(data,'email');
    }
});

$(".item-phone").on('keyup',function (e){
    e.preventDefault();
    let data = new FormData;
    let phone = $(".item-phone").val();
    console.log(phone);
    data.append('field',"phone")
    data.append('value',phone)
    findEmailOrPhone(data,'phone');
});

function findEmailOrPhone(value,field)
{
    $(".item-error-"+field).text("");
    $.ajax({
        url: "find-email-or-phone",
        type: "post",
        data: value,
        processData: false,
        contentType: false,
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        success: function (data) {
            if (data.success == "error") {
                $(".item-error-" + field).text(data.msg);
            }else{
                $(".item-error-" + field).text("");
            }
        },
        error: function (errors) {
            var indices = errors.responseJSON.errors;
            $.each(indices, function (index, value) {
                $(".item-error-" + index).append(value);
                $(".item-error-" + index).show();
            });
        }
    });
}

