$(document).ready(function (){

    $("#table-logistica").DataTable({
        proccesing: true,
        serverSide: true,
        language: {
            "url": "//cdn.datatables.net/plug-ins/1.10.16/i18n/Spanish.json"
        },
        responsive: true,
        ajax: 'get-guest',
        columns: [
            {data: 'id', name: 'id'},
            {data: 'name', name: 'name'},
        ],
        "columnDefs": [{
            "targets": 2,
            "data": "download_link",
            "render": function (data, type, row, meta) {
                console.log(row)
                let status = get_global_status(row.status);
                return '<a href="'+ '/detalles-viaje/'+row.slug +  '" data-toggle="tooltip" data-placement="top" ' +
                    'class="btn btn-primary" title="Mostrar detalles vuelo"><span class="fas fa-business-time">' +
                    '</span>' +
                    '</a> ' +
                    '<button class="btn btn-success open-hospedaje" data-id ="'+ row.id +'" data-val="' + row + '" ' +
                    'data-toggle="tooltip" title="Agregar hospedaje"><i class="fas fa-bed"></i></button>'
            }
        }]
    })

    $("#form-agregar-itinerario").on('submit',function (e){
        e.preventDefault()
        let data = new FormData(this)
        $(".loading").addClass("loading_")
        sendData('../add-itinerario',data,'POST')
    })

    window.deleteItinerario = function (id,invitado_id) {
        let data = new FormData()
        data.append('id',id)
        data.append('invitado_id',invitado_id)
        swal.fire({
            title: '¿Estas seguro?',
            text: "Este itinerario sera eliminado",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Eliminar!',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if(result.value) {
                sendData('../eliminar-itinerario',data,'POST')
            }
        })
    }

    $("#table-logistica tbody").on('click','.open-hospedaje', function (e){
        e.preventDefault();
        let logistica_table = $("#table-logistica").DataTable();
        let rowData = logistica_table.row($(this).parents('tr')).data();
        $(".item-estancia-invitado_id").val($(this).data('id'))
        $('#form-estancia')[0].reset()

        $.each(rowData, function (index, value) {

            if (index === "invitados_logistica") {
                $.each(value, function (key,item){
                    console.log(key,item)
                    $(".item-estancia_"+key).val(item)
                    $(".item-"+key).val(item)
                    if (key === "hotel_id") {
                        $('.item-estancia-hotel_id').val(item).trigger('change');
                    }
                })
            }

            $(".item-estancia-" + index).val(value)
            $(".item-" + index).val(value)

        });
        const modalEstancia = new bootstrap.Modal('#modal-estancia')
        modalEstancia.show();
    })

    $("#form-estancia").on('submit',function (e){
        e.preventDefault();
        var data = new FormData(this);
        sendData('send-estancia',data,'POST')
    });


    function sendData(route,data,method) {
        $.ajax({
            url: route,
            type: method,
            data: data,
            processData: false,
            contentType: false,
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            success: function (data) {
                $(".loading").removeClass("loading_")
                alertSuccess(data.msg)
                location.reload();
            },
            error: function (errors) {
                let indices = errors.responseJSON.errors;
                return indices;
            }
        });
    }
})
