$(document).ready(function () {
    $(".box-presentador").hide();
    $(".box-moderador").hide();
    $(".box-colaborador").hide();
    $("#table-events").DataTable({
        proccesing: true,
        serverSide: true,
        language: {
            "url": "//cdn.datatables.net/plug-ins/1.10.16/i18n/Spanish.json"
        },
        responsive: true,
        ajax: 'get-events',
        columns: [
            {data: 'id', name:'id',"searchable": false, },
            {data: 'fecha', name:'fecha'},
            {
                data: 'hora_inicio',
                render: function (data, type, row, meta) {
                    return row.hora_inicio + ' - ' + row.hora_fin
                }
            },
            {data: 'eventos_detalles.foros_eventos.name', name:'eventos_detalles.foros_eventos.name'},
            {data: 'evento_tipo.name', name:'evento_tipo.name',"searchable": false},
            {data: 'evento_programacion.name', name:'evento_programacion.name'},
            {data: 'nombre_evento', name:'nombre_evento'},
            {data: 'descripcion_alterna', name:'descripcion_alterna',
                "render": function(data, type, row, meta){
                    data = '<a href="#" data-description="' + row.descripcion + '" class="view-description">' + row.descripcion + '</a>';
                    return data;
                }
            },
            {data: 'participantes', name:'participantes'}
        ],
        "columnDefs": [{
            "targets": 9,
            "data": "download_link",
            "render": function (data, type, row, meta) {
                let status = get_global_visible_event(row.is_visible);
                return '<button data-toggle="tooltip" data-placement="top" title="Editar" class="btn btn-primary ' +
                    'edit-event btn-sm"' +
                    'data-id ="' + row.id + '"><i class="far fa-edit"></i></button> ' +

                    '<button data-toggle="tooltip" data-placement="top" title="Agregar imagen" class="btn btn-info ' +
                    'add-img btn-sm mt-1 mb-1"' +
                    'data-id ="' + row.id + '"><i class="fas fa-images"></i></button> ' +

                    '<a href="#" data-toggle="tooltip" data-placement="top" title="'+ status.title +'" ' +
                    'class="btn mt-1 mb-1 btn-' + status.color_clas +  ' view-event btn-sm"  ' +
                    'data-id ="' + row.id + '"><span class="' + status.icon + '"></span></a> '+

                    '<a href="#" data-toggle="tooltip" data-placement="top" title="Eliminar" ' +
                    'class="btn btn-danger delete-event btn-sm mt-1 mb-1"  ' +
                    'data-id ="' + row.id + '"><i class="fa-solid fa-trash-can"></i></span></a> '
            }
        }]
    });

    $("#add-evento").on('click', function (e) {
        e.preventDefault()
        $('#form-add-evento')[0].reset()
        $(".item-eventos-id").val("")
        $('.item-evento-invitado_id').val("").trigger('change')
        $('.item-evento-tipo_id').val("").trigger('change')
        $('.item-evento-programacion_id').val("").trigger('change')
        $('.item-evento-presentador_id').val("").trigger('change')
        $('.item-evento-moderador_id').val("").trigger('change')
        $('.item-evento-formato').val("").trigger('change')
        $('.item-evento-sede_id').val("").trigger('change')

        $(".title-eventos-text").text("Agregar evento.")
        const modalEvento = new bootstrap.Modal('#modal-eventos')
        modalEvento.show()
    })

    $("#table-events tbody").on('click','.view-event',function (e){
        e.preventDefault()
        let data = new FormData()
        data.append('id',$(this).data('id'))
        sendData('view-event',data,0)
    })

    $("#table-events tbody").on('click','.delete-event',function (e){
        e.preventDefault()
        let data = new FormData()
        data.append('id',$(this).data('id'))
        swal.fire({
            title: '¿Estas seguro?',
            text: "¿Deseas eliminar este evento? Sera imposible recuperarlo.",
            type: 'warning',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Eliminar!',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if(result.value) {
                $(".loading").addClass("loading_");
                sendData('delete-event',data,0)
            }
        })

    })

    $("#table-events tbody").on('click','.add-img',function (e){
        e.preventDefault()
        $(".item-evento-img-id").val($(this).data('id'))
        const modalImgEvento = new bootstrap.Modal('#modal-imagen-eventos')
        modalImgEvento.show()
    })

    $("#table-events tbody").on('click','.edit-event', function (e) {
        e.preventDefault()
        $(".title-eventos-text").text("Editar evento.")
        let data = new FormData();
        data.append('id',$(this).data('id'))
        $(".loading").addClass("loading_");
        $.ajax({
            url: "get-event-by-id",
            type: "POST",
            data: data ,
            processData: false,
            contentType: false,
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            success: function (response) {
                let invitados = new Array();
                let presentadores = new Array();
                let moderadores = new Array();

                $.each(response,function (index,value){
                    $(".item-eventos-"+index).val(value)

                    /*if (index === "evento_participante") {
                        $.each(value,function (key,element){
                            if (element.tipo_participacion == "participante") {
                                presentadores = element
                            }

                        })

                    }
                    return "ok";*/


                    if (index === "tipo_id") {
                        $('.item-evento-tipo_id').val(value).trigger('change');
                        if (value == 10 || value == 16) {
                            /*$.each(data.presentador, function (index, value) {
                                presentadores.push('id', value.presentador_id);
                            });*/
                            /*$(".item-presentadores").val(presentadores).trigger("chosen:updated");*/

                            $(".presentadores").show();
                            $(".moderadores").hide();
                        } else if (value == 11) {
                            console.log()
                            /*$.each(data.moderador, function (index, value) {
                                moderador.push('id', value.moderador_id);
                            });
                            $(".item-moderadores").val(moderador).trigger("chosen:updated");
*/
                            $(".presentadores").hide();
                            $(".moderadores").show();

                        } else {
                            $(".presentadores").hide();
                            $(".moderadores").hide();
                        }
                    }

                    if (index === "programacion_id") {
                        $('.item-evento-programacion_id').val(value).trigger('change');
                    }

                    if (index === "formato") {
                        $('.item-evento-formato').val(value).trigger('change');
                    }

                    if (index === "eventos_detalles" ) {
                        setTimeout(function(){
                            $('.item-evento-sede_id').val(value.sede_id).trigger('change');
                        },1000);
                    }
                })

                $.each(response.evento_participante,function (index,value) {
                    if (value.tipo_participacion == "participante") {
                        invitados.push('id',value.invitado_id);
                    }
                    if (value.tipo_participacion == "presentadores") {
                        presentadores.push('id',value.invitado_id);
                    }
                    if (value.tipo_participacion == "moderador") {
                        moderadores.push('id',value.invitado_id);
                    }
                });

                $('.item-evento-invitado_id').val(invitados).trigger('change');
                $('.item-evento-presentador_id').val(presentadores).trigger('change');
                $('.item-evento-moderador_id').val(moderadores).trigger('change');

                $.each(response.evento_book,function (index,value) {
                    let pos = index + 1 ;
                    $(".item-title"+pos).val(value.titulo);
                    $(".quantity"+[pos]).val(value.quantity_for_presenter)

                    if (value.is_presenter == 1 ) {
                        $(".item-for_promotor"+pos).prop('checked', true);
                    }
                    if (value.quantity_for_presenter > 0) {
                        $(".quantity"+[pos]).attr("readonly", false);
                    }
                });


                $(".loading").removeClass("loading_");
            }
        })

        const modalEvento = new bootstrap.Modal('#modal-eventos')
        modalEvento.show()
    })

    $("#form-add-evento").on('submit', function (e){
        e.preventDefault()
        $(".loading").addClass("loading_");
        let data = new FormData(this)
        sendData('add-event',data,'modal-eventos')
    })

    $("#form-add-imagen-evento").on('submit', function (e){
        e.preventDefault()
        $(".loading").addClass("loading_");
        let data = new FormData(this)
        sendData('add-imagen-event',data,'modal-imagen-eventos')
    })

    function sendData(route,data,modal)
    {
        $.ajax({
            url: route,
            type: "POST",
            data: data,
            processData: false,
            contentType: false,
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            success: function (response) {
                if (response.success === "success") {
                    alertSuccess(response.msg)
                    if (modal != 0) {
                        bootstrap.Modal.getOrCreateInstance(document.getElementById(modal)).hide()
                    }
                    $("#table-events").DataTable().ajax.reload( null, false );
                    /*location.reload()*/
                }else{
                    console.log(response)
                    alertError(response.msg)
                }

                $(".loading").removeClass("loading_");
            },
            error: function (errors) {
                $(".loading").removeClass("loading_");
                var indices = errors.responseJSON.errors;
                $.each(indices, function (index, value) {
                    console.log(index, value);
                    $(".item-eventos-error-" + index).append(value);
                    $(".item-eventos-error-" + index).show();
                });
            }
        })
    }

})
