$(document).ready(function (){
    $('.select-eventos-prensa-invitado_id').select2({width: '100%', dropdownParent: $('#modal-event-press')});
    $('.select-eventos-prensa-medio_id').select2({width: '100%', dropdownParent: $('#modal-event-press')});
    $('.select-eventos-prensa-tipo_id').select2({width: '100%', dropdownParent: $('#modal-event-press')});

    $("#table-eventos-prensa").DataTable({
        proccesing: true,
        serverSide: true,
        language: {
            "url": "//cdn.datatables.net/plug-ins/1.10.16/i18n/Spanish.json"
        },
        responsive: true,
        ajax: 'get-event-press',
        columns: [
            {data: 'id', name: 'id'},
            {data: 'eventos_prensa.name', name: 'eventos_prensa.namex'},
            {data: 'tipos_prensa.name', name: 'tipos_prensa.name'},
            {data: 'invitado' , name: 'invitado'},
            {data: 'participantes', name: 'participantes'},

        ],
        "columnDefs": [{
            "targets": 5,
            "data": "download_link",
            "render": function (data, type, row, meta) {
                return '<button data-toggle="tooltip" data-placement="top" title="Editar" class="btn btn-primary ' +
                    'edit-press-event btn-sm"' +
                    'data-data ="' + row + '"><i class="far fa-edit"></i></i></button> '+

                    '<a href="#" data-toggle="tooltip" data-placement="top" title="'+ status.title +'" ' +
                    'class="btn btn-danger delete-event-press btn-sm"  ' +
                    'data-id ="' + row.id + '"><i class="fas fa-trash-alt"></i></a> '
            }
        }]
    })

    const list_event_press = $("#table-eventos-prensa").DataTable();

    $("#add-event-press").on('click',function (e){
        e.preventDefault()
        $('#form-event-press')[0].reset();
        $('.select-eventos-prensa-invitado_id').append("").trigger('change');
        $('.select-eventos-prensa-medio_id').append("").trigger('change');
        $('.select-eventos-prensa-tipo_id').append("").trigger('change');
        $(".title-event-press-text").text("Agregar evento")
        $(".item-eventos-prensa-id").val("");
        const modalEventPress = new bootstrap.Modal('#modal-event-press')
        modalEventPress.show()
    })

    $("#table-eventos-prensa tbody").on('click', '.edit-press-event', function (e) {
        e.preventDefault()
        $(".title-event-press-text").text("Editar")

        let rowData = list_event_press.row($(this).parents('tr')).data();
        $.each(rowData, function (index, value) {
            $(".item-eventos-prensa-"+index).val(value)
            if (index == "invitado_id") {
                $('.item-eventos-prensa-invitado_id').append(value).trigger('change');
            }

            if (index == "medio_id") {
                $('.item-eventos-prensa-medio_id').append(value).trigger('change');
            }
            if (index == "tipo_id") {
                $('.item-eventos-prensa-tipo_id').append(value).trigger('change');
            }
        });
        const modalEventPress = new bootstrap.Modal('#modal-event-press')
        modalEventPress.show()
    })

    $("#table-eventos-prensa tbody").on('click','.delete-event-press',function (e){
        e.preventDefault()
        let data = new FormData();
        data.append('id',$(this).data('id'))

        swal.fire({
            title: '¿Estas seguro?',
            text: "Esta actividad sera eliminada",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Eliminar!',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if(result.value) {
                sendDataInterview('delete-event-press','POST',data,'1')
            }
        })

    })

    $("#form-event-press").on('submit',function (e){
        e.preventDefault()
        let data = new FormData(this)
        $(".loading").addClass("loading_")
        sendDataInterview("save-interview","POST",data,"modal-event-press")
    })

    /**
     * Funcion que se encarga de enviar los datos al controlador
     */
    function sendDataInterview(ruta,method,data,nameModal) {
        $.ajax({
            url: ruta,
            type: method,
            data: data,
            processData: false,
            contentType: false,
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            success: function (data) {
                $(".loading").removeClass("loading_");
                if (data.success == "error") {
                    alertError(data.msg)
                }
                if (data.success == "success"){
                    alertSuccess(data.msg)
                    $("#table-eventos-prensa").DataTable().ajax.reload();
                    if ( nameModal === "modal-event-press" ) {
                        bootstrap.Modal.getOrCreateInstance(document.getElementById(nameModal)).hide()
                    }
                }

            },
            error: function (errors) {
                $(".loading").removeClass("loading_");
                var indices = errors.responseJSON.errors;
                $.each(indices, function (index, value) {
                    $("#error-" + index).append(value);
                    $("#error-" + index).show();
                });
            }
        });
    }

})
