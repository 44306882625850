window.getVal = function (item) {

    let data = new FormData()
    clearSelectTypeTransport()
    $(".loading").addClass("loading_")
    data.append('type_transport',item.value)
    $.ajax({
        url: "../get-transports",
        type: "POST",
        data: data,
        processData: false,
        contentType: false,
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        success: function (data) {
            $(".loading").removeClass("loading_")
            $.each(data,function (index,value){
                $('.item-logistica-transport_id').append($('<option>', {
                    value: value.id,
                    text: value.name
                }));

            })
        },
        error: function (errors) {
            let indices = errors.responseJSON.errors;
            return indices;
        }
    });
}


window.editItinerario = function (itinerario) {
    const data = new FormData()
    data.append('id',itinerario.id)
    clearSelectTypeTransport()
    $(".loading").addClass("loading_")

    $.ajax({
        url: "../get-transport-by-guest",
        type: "POST",
        data: data,
        processData: false,
        contentType: false,
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        success: function (data) {
            $(".item-logistica-tipo_transporte option[value='"+ data.tipo  +"']").attr("selected",true)
            $(".item-logistica-tipo_transporte").trigger('change')
            $.each(itinerario,function(index,value){
                $(".item-logistica-"+index).val(value);
            })

            $("html, body").animate({ scrollTop: 0 }, 800); //Sube la vista al inicio del form
            setTimeout(function(){
                $(".item-logistica-transport_id option[value='"+ itinerario.transport_id  +"']").attr("selected",true);
                $(".item-logistica-transport_id").trigger('change')
                $(".loading").removeClass("loading_")
            },1000);
            $(".txt-status-itinerario").text("Este registro se estaría actualizando.")
        }
    });
}


window.clearForm = function (form) {
    $(".loading").addClass("loading_")
    setTimeout(function(){
        $(".item-logistica-tipo_transporte").val('').trigger('change')
        $(".item-logistica-transport_id").val('').trigger('change')
        $('#'+form)[0].reset()
        $(".loading").removeClass("loading_")
    },1000);
    $(".txt-status-itinerario").text("")

}

function clearSelectTypeTransport() {
    $('.item-logistica-transport_id').empty()
    let newOption = $('<option value="">Seleccionar una opción</option>');
    $('.item-logistica-transport_id').append(newOption).trigger('change');
}
